import React from "react";
import { SEO } from "wmk-lib";
import Layout from "../components/layout/MainLayout";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";

const NotFoundPage = () => (
  <Layout>
    <SEO
      title={"Page Not Found (404)"}
      path="/"
      slug={"404"}
      description={
        "Error 404: The page your are looking for doesn't exist at this address."
      }
      ogImage={null}
      twitterImage={null}
      twitterHandle={twitter}
      siteTitle={siteTitle}
      baseUrl={baseUrl}
    />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
